<template>
  <div class="card card-body shadow-sm csv_upload">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <app-modal-confirm
                         ref="modal_show_confirm"
                         :modal-header-title="$t('common.upload submit')"
                         @confirmed="importCSVData(ENDPOINT.CSV_IMPORT_CUSTOMER)"
                         :cancel-text="$t('common.cancel')"
                         :confirm-text-text="$t('csv_upload.confirm_text')"
      >
        <template v-slot:body>
          <p class="confirm-body">{{ $t('csv_upload.confirm_modal_title') }}</p>
        </template>
      </app-modal-confirm>
        <template>
          <div class="card card-transparent">
            <div class="card-header search-container">
              <div class="row header-title"><h4><b> {{ customerImportHeader }}</b></h4></div>
              <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-sm-6">
                  <div class="form-group row">
                    <label class="col-xl-4 col-lg-12 col-sm-8">{{ filters.apartment_id_equal.label }}
                      <span class="app-require">＊</span></label>
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                      <app-select :name="filters.apartment_id_equal.name"
                                  input-style="normal"
                                  v-model="filters.apartment_id_equal.value"
                                  :options-data="meta.apartments"
                                  @change="customerCheckRequire"
                                  rules="required"/>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-lg-6 col-sm-6">
                  <div class="form-group row">
                    <label class="col-xl-4 col-lg-12 col-sm-8">{{ filters.file_import.label }}
                      <span class="app-require">＊</span></label>
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                      <div class="input-group">
                        <div class="custom-file">
                          <input required type="file"
                                 :name="filters.file_import.name"
                                 class="custom-file-input"
                                 id="inputGroupFile01"
                                 aria-describedby="inputGroupFileAddon01"
                                 accept="text/csv"
                                 @change="changeFile()"
                                 ref="importFile"
                                 rules="required">
                          <label class="custom-file-label" for="inputGroupFile01"> {{fileName}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-12 col-sm-12 align-left">
                  <span class="h6">{{ $t('csv_upload.description_label') }}</span>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-12 col-sm-12 align-left">
                  <span class="h6">{{ $t('csv_upload.description_label_2') }}</span>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-12 col-sm-12 align-left">
                <span class="h6">{{ $t('csv_upload.description_label_3') }}</span>
                </div>
              </div>
              <div class="row justify-content-center custom_description custom-text">
                <div class="col-xl-8 col-lg-12 col-sm-12 align-left">
                  <a class="" :href="urlTemplateCustomer">
                    テンプレートファイル
                  </a>
                </div>
              </div>
              <div class="row justify-content-center custom_description custom-text" v-if="urlFileCustomerError !== undefined">
                <div class="col-xl-8 col-lg-8 col-sm-8 align-left">
                  <a class="" :href="urlFileCustomerError">
                    ここをクリックするとエラー情報をダウンロードできます
                  </a>
                </div>
              </div>

              <div class="search-content">
                <validation-observer v-slot="{ passes, valid, validated }">
                  <form
                    novalidate
                    autocomplete="off"
                    @submit.prevent="passes(searchHandle)"
                  >
                    <slot name="filters"></slot>
                    <div class="m-t-10 row justify-content-center">
                      <button
                        :disabled="disable_upload"
                        class="btn btn-primary text-complete"
                        type="submit"
                        @click="handleOpenConfirmModal()"
                      >
                        {{ $t("common.Upload execution") }}
                      </button>
                    </div>
                  </form>
                </validation-observer>
              </div>
            </div>
          </div>
        </template>


      <app-modal-confirm
        ref="postal_code_modal_show_confirm"
        :modal-header-title="$t('common.upload submit')"
        @confirmed="importCSVData(ENDPOINT.CSV_IMPORT_POSTAL_CODE)"
        :cancel-text="$t('common.cancel')"
        :confirm-text-text="$t('csv_upload.confirm_text')"
      >
        <template v-slot:body>
          <p class="confirm-body">{{ $t('csv_upload.postal_code_confirm_modal_title') }}</p>
        </template>
      </app-modal-confirm>
      <template>
        <div class="card card-transparent">
          <div class="card-header search-container">
            <div class="row header-title"><h4><b> {{ postalCodeImportHeader }}</b></h4></div>
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-6 col-sm-6">
                <div class="form-group row">
                  <label class="col-xl-4 col-lg-12 col-sm-8">{{ postal_code_filters.file_import.label }}
                    <span class="app-require">＊</span></label>
                  <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="input-group">
                      <div class="custom-file">
                        <input required type="file"
                               :name="postal_code_filters.file_import.name"
                               class="custom-file-input"
                               id="inputGroupFile02"
                               aria-describedby="inputGroupFileAddon02"
                               accept="text/csv"
                               @change="postalCodeChangeFile()"
                               ref="postCodeImportFile"
                               rules="required">
                        <label class="custom-file-label" for="inputGroupFile02"> {{postalCodeFileName}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-12 col-sm-12 align-left">
                <span class="h6">{{ $t('csv_upload.postal_code_description_label') }}</span>
              </div>
            </div>
            <div class="row justify-content-center custom-text">
              <div class="col-xl-8 col-lg-12 col-sm-12 align-left">
                <a class="" href="https://www.post.japanpost.jp/zipcode/dl/kogaki-zip.html" target="_blank">
                  {{ $t('csv_upload.postal_code_description_label_2') }}
                </a>
              </div>
            </div>
            <div class="row justify-content-center custom_description custom-text">
              <div class="col-xl-8 col-lg-12 col-sm-12 align-left">
                <a class="" :href="urlTemplatePostalCode">
                  テンプレートファイル
                </a>
              </div>
            </div>
            <div class="row justify-content-center custom_description custom-text" v-if="urlFilePostalCodeError !== undefined">
              <div class="col-xl-8 col-lg-8 col-sm-8 align-left">
                <a class="" :href="urlFilePostalCodeError">
                  ここをクリックするとエラー情報をダウンロードできます
                </a>
              </div>
            </div>

            <div class="search-content">
              <validation-observer v-slot="{ passes, valid, validated }">
                <form
                  novalidate
                  autocomplete="off"
                  @submit.prevent="passes(searchHandle)"
                >
                  <slot name="filters"></slot>
                  <div class="m-t-10 row justify-content-center">
                    <button
                      :disabled="postalCodeDisableUpload"
                      class="btn btn-primary text-complete"
                      type="submit"
                      @click="handleOpenPostalCodeConfirmModal()"
                    >
                      {{ $t("common.Upload execution") }}
                    </button>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
        </div>
      </template>

      <app-modal-confirm
        ref="delete_invoice_modal_show_confirm"
        :modal-header-title="$t('common.upload submit')"
        @confirmed="importCSVData(ENDPOINT.CSV_IMPORT_DELETE_INVOICE)"
        :cancel-text="$t('common.cancel')"
        :confirm-text-text="$t('csv_upload.confirm_text')"
      >
        <template v-slot:body>
          <p class="confirm-body">{{ $t('csv_upload.delete_invoice_confirm_modal_title') }}</p>
        </template>
      </app-modal-confirm>
      <template>
        <div class="card card-transparent">
          <div class="card-header search-container">
            <div class="row header-title"><h4><b> {{ deleteInvoiceImportHeader }}</b></h4></div>
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-6 col-sm-6">
                <div class="form-group row">
                  <label class="col-xl-4 col-lg-12 col-sm-8">{{ delete_invoice_filters.file_import.label }}
                    <span class="app-require">＊</span></label>
                  <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="input-group">
                      <div class="custom-file">
                        <input required type="file"
                               :name="delete_invoice_filters.file_import.name"
                               class="custom-file-input"
                               id="inputGroupFile03"
                               aria-describedby="inputGroupFileAddon03"
                               accept="text/csv"
                               @change="deleteInvoiceChangeFile()"
                               ref="deleteInvoiceImportFile"
                               rules="required">
                        <label class="custom-file-label" for="inputGroupFile03"> {{deleteInvoiceFileName}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-12 col-sm-12 align-left">
                <span class="h6">{{ $t('csv_upload.description_label_4') }}</span>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-12 col-sm-12 align-left">
                <span class="h6">{{ $t('csv_upload.description_label_5') }}</span>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-12 col-sm-12 align-left">
                <span class="h6">{{ $t('csv_upload.description_label_6') }}</span>
              </div>
            </div>
            <div class="row justify-content-center custom_description custom-text">
              <div class="col-xl-8 col-lg-12 col-sm-12 align-left">
                <a class="" :href="urlTemplateInvoice">
                  テンプレートファイル
                </a>
              </div>
            </div>
            <div class="row justify-content-center custom_description custom-text" v-if="urlFileInvoiceError !== undefined">
              <div class="col-xl-8 col-lg-8 col-sm-8 align-left">
                <a class="" :href="urlFileInvoiceError">
                  ここをクリックするとエラー情報をダウンロードできます
                </a>
              </div>
            </div>

            <div class="search-content">
              <validation-observer v-slot="{ passes, valid, validated }">
                <form
                  novalidate
                  autocomplete="off"
                  @submit.prevent="passes(searchHandle)"
                >
                  <slot name="filters"></slot>
                  <div class="m-t-10 row justify-content-center">
                    <button
                      :disabled="deleteInvoiceDisableUpload"
                      class="btn btn-primary text-complete"
                      type="submit"
                      @click="handleOpenDeleteInvoiceConfirmModal()"
                    >
                      {{ $t("common.Upload execution") }}
                    </button>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
        </div>
      </template>

      <app-modal-confirm ref="invoice_modal_show_confirm" :modal-header-title="$t('common.upload submit')"
        @confirmed="importCSVData(ENDPOINT.CSV_IMPORT_INVOICE)" :cancel-text="$t('common.cancel')"
        :confirm-text-text="$t('csv_upload.confirm_text')">
        <template v-slot:body>
          <p class="confirm-body">請求書・領収書取り込みCSVをアップロードすると、指定されたアップロード月の請求書または領収書PDFが作成されます。<br/>
            同年月でのPDFファイルが既に生成されている場合は上書きされます。続行してもよろしいですか？</p>
        </template>
      </app-modal-confirm>
      <template>
        <div class="card card-transparent">
          <div class="card-header search-container">
            <div class="row header-title">
              <h4><b> {{ $t("csv_upload.honobono_accountin_upload_header") }}</b></h4>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-6 col-sm-6">
                <div class="form-group row">
                  <label class="col-xl-2 col-lg-6 col-sm-4 ">{{ $t("csv_upload.month_upload") }}
                    <span class="app-require">＊</span></label>
                  <div class="col-xl-6 col-lg-6 col-md-3 col-sm-12">
                    <month-picker-input ref="month" lang="ja" :default-month="selectMonth.month"
                      :default-year="selectMonth.year" @change="changeMonth" :class="'c_input_picker month_invoice '"
                      :show-year="true" input-pre-filled date-format="%Y年%n">
                    </month-picker-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-6 col-sm-6">
                <div class="form-group row">
                  <label class="col-xl-2 col-lg-6 col-sm-4 ">{{ $t("csv_upload.type") }}
                    <span class="app-require">＊</span></label>
                  <div class="col-xl-5 col-lg-8 col-sm-9 gr-icheck">
                    <div class="icheck-primary d-inline app-inline m-l-20">
                      <input class="form-check-input" v-model="target_type" type="radio" name="inlineRadioOptions"
                        id="inlineRadio1" value="1">
                      <label for="inlineRadio1">請求書</label>
                    </div>
                    <div class="icheck-primary d-inline app-inline m-l-20">
                      <input class="form-check-input" v-model="target_type" type="radio" name="inlineRadioOptions"
                        id="inlineRadio3" value="2">
                      <label for="inlineRadio3">領収書</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-6 col-sm-6">
                <div class="form-group row">
                  <label class="col-xl-4 col-lg-12 col-sm-8">{{ $t("csv_upload.file_detail_99999") }}</label>
                  <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="input-group">
                      <div class="custom-file">
                        <input required type="file" :name="detail_import.file_import_99999.name" class="custom-file-input"
                          id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" accept="text/csv"
                          @change="invoiceChangeFile99999()" ref="invoiceImportFile99999" rules="required">
                        <label class="custom-file-label" for="inputGroupFile04"> {{ invoiceFileName99999 }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-6 col-sm-6">
                <div class="form-group row">
                  <label class="col-xl-4 col-lg-12 col-sm-8">{{ $t("csv_upload.file_detail_88888") }}</label>
                  <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="input-group">
                      <div class="custom-file">
                        <input required type="file" :name="detail_import.file_import_88888.name" class="custom-file-input"
                               id="inputGroupFile06" aria-describedby="inputGroupFileAddon06" accept="text/csv"
                               @change="invoiceChangeFile88888()" ref="invoiceImportFile88888" rules="required">
                        <label class="custom-file-label" for="inputGroupFile06"> {{ invoiceFileName88888 }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center ">
              <div class="col-xl-8 col-lg-6 col-sm-6">
                <div class="form-group row">
                  <label class="col-xl-8 col-lg-12 col-sm-12 align-left">{{ $t("csv_upload.file_invoice") }}</label>
                  <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="input-group">
                      <div class="custom-file">
                        <input :required="''" type="file" :name="invoice_import.file_import.name"
                          class="custom-file-input" id="inputGroupFile05" aria-describedby="inputGroupFileAddon05"
                          accept="text/csv" @change="invoiceTaxChangeFile()" ref="invoiceTaxImportFile" rules="required">
                        <label class="custom-file-label" for="inputGroupFile05"> {{ invoiceTaxFileName }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="target_type==2" class="row justify-content-center ">
              <div class="col-xl-8 col-lg-6 col-sm-6">
                <div class="form-group row">
                  <label class="col-xl-8 col-lg-12 col-sm-12 align-left">領収日CSVファイル</label>
                  <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="input-group">
                      <div class="custom-file">
                        <input required type="file" name="receipt_file"
                               class="custom-file-input" id="inputGroupFile07" aria-describedby="inputGroupFileAddon07"
                               accept="text/csv" @change="receiptChangeFile()" ref="receiptFile" rules="required">
                        <label class="custom-file-label" for="inputGroupFile07"> {{ receiptFileName }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Start 2719-->
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-6 col-sm-6">
                <div class="form-group row">
                  <label class="col-xl-4 col-lg-12 col-sm-8">支払者名CSVファイル</label>
                  <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="input-group">
                      <div class="custom-file">
                        <input required type="file" :name="payerNameCsvFile" class="custom-file-input"
                               id="inputGroupFile08" aria-describedby="inputGroupFileAddon08" accept="text/csv"
                               @change="payerNameCsvFile()" ref="payerNameCsvFile" rules="required">
                        <label class="custom-file-label" for="inputGroupFile08"> {{ payerNameCsvFileName }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End 2719-->
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-12 col-sm-12 align-left">
                <span class="h6">{{ $t('csv_upload.description_label_7') }}</span>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-12 col-sm-12 align-left">
                <span class="h6">{{ $t('csv_upload.description_label_8') }}</span>
              </div>
            </div>
            <div class="row justify-content-center custom_description ">
              <div class="col-xl-8 col-lg-12 col-sm-12 align-left">
                <span class="h6">{{ $t('csv_upload.description_label_9') }}</span>
              </div>
            </div>
            <div class="row justify-content-center ">
              <div class="col-xl-8 col-lg-12 col-sm-12 align-left">
                <a class="" :href="urlTemplateDetail">
                  テンプレートファイル（明細付）
                </a>
              </div>
            </div>
            <div class="row justify-content-center ">
              <div class="col-xl-8 col-lg-12 col-sm-12 align-left">
                <a class="" :href="urlTemplateDetailInvoice">
                  テンプレートファイル（消費税内訳付）
                </a>
              </div>
            </div>
            <div class="search-content">
              <validation-observer v-slot="{ passes, valid, validated }">
                <form novalidate autocomplete="off" @submit.prevent="passes(searchHandle)">
                  <slot name="filters"></slot>
                  <div class="m-t-10 row justify-content-center">
                    <button :disabled="invoiceDisableUpload" class="btn btn-primary text-complete" type="submit"
                      @click="handleOpenInvoiceConfirmModal()">
                      {{ $t("common.Upload execution") }}
                    </button>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
        </div>
      </template>
    </validation-observer>
  </div>
</template>

<script>
import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
import {ENDPOINT} from "../../../constants/api";
import AppDownloadTemplate from "@components/_common/download/AppDownloadTemplate";
import AppModalConfirm from "@components/_common/popup/AppModalConfirm";
import axios from 'axios';
import { MonthPickerInput } from 'vue-month-picker'
export default {
  name: 'csv_upload',
  components: {
    "app-download-template": AppDownloadTemplate,
    AppDateRangePicker,
    AppModalConfirm,
    MonthPickerInput,
  },
  data() {
    return {
      fileName: 'Choose file',
      postalCodeFileName: 'Choose file',
      deleteInvoiceFileName: 'Choose file',
      idCache: null,
      disable_upload: false,
      postalCodeDisableUpload: false,
      deleteInvoiceDisableUpload: false,
      paramDownload: {},
      urlFileCustomerError: undefined,
      urlFilePostalCodeError: undefined,
      urlFileInvoiceError: undefined,
      urlTemplateCustomer: "",
      urlTemplatePostalCode: "",
      urlTemplateInvoice: "",
      customerImportHeader: this.$t("csv_upload.upload_customer_header"),
      postalCodeImportHeader: this.$t("csv_upload.upload_postcode_header"),
      deleteInvoiceImportHeader: this.$t("csv_upload.upload_delete_invoice_header"),
      invoiceFileName99999: 'Choose file',
      invoiceFileName88888: 'Choose file',
      invoiceTaxFileName: 'Choose file',
      receiptFileName: 'Choose file',
      payerNameCsvFileName: 'Choose file',
      invoiceDisableUpload: false,
      detailDisableUpload: false,
      urlTemplateDetail: "",
      urlTemplateDetailInvoice: "",
      target_type: 1,
      filters: {
        apartment_id_equal: {
          name: "apartment_id",
          condition: "equal",
          label: this.$t("common.mansion")
        },
        file_import: {
          name: "file_import",
          condition: "equal",
          label: this.$t("csv_upload.file_select")
        },
        month: {
          name: "month",
          label: this.$t("reservation.date"),
          value: moment().format('YYYY/MM')
        },
      },
      postal_code_filters: {
        file_import: {
          name: "postal_code_file_import",
          condition: "equal",
          label: this.$t("csv_upload.file_select")
        },
      },
      delete_invoice_filters: {
        file_import: {
          name: "postal_code_file_import",
          condition: "equal",
          label: this.$t("csv_upload.file_select")
        },
      },
      detail_import: {
        file_import_99999: {
          name: "detail_code_99999_file_import",
        },
        file_import_88888: {
          name: "detail_code_88888_file_import",
        },
      },
      invoice_import: {
        file_import: {
          name: "invoice_code_file_import",
        },
      },
      meta: {
        apartments: [],
      },
      selectMonth: {
        month: moment().month() + 1,
        year: moment().year(),
      },
      endPoint: ''
    };
  },
  methods: {
    async importCSVData(enpointUploadData){
      let form = new FormData();
      let file = null;
      let invoiceFile = null;
      let invoiceTaxFile = null;
      let receiptFile = null;
      let payerNameCsvFile = null;
      let admin_import_id = this.$store.getters["auth/getUser"].id;
      if (enpointUploadData === ENDPOINT.CSV_IMPORT_CUSTOMER) {
        let apartmentId = this.filters.apartment_id_equal.value;
        form.append('apartment_id', apartmentId);
        file = this.$refs.importFile.files[0];
      } else if (enpointUploadData === ENDPOINT.CSV_IMPORT_POSTAL_CODE) {
        file = this.$refs.postCodeImportFile.files[0];
      } else if (enpointUploadData === ENDPOINT.CSV_IMPORT_INVOICE) {
        form.append('month', this.filters.month.value);
        form.append('target_type', this.target_type);
        // form.append('invoiceFileName', this.$refs.invoiceImportFile.files[0].name)
        // invoiceFile = this.$refs.invoiceImportFile.files[0];
        // form.append('invoiceFile', invoiceFile);
        if(this.$refs.invoiceImportFile99999.files[0] != undefined)
        {
          form.append('invoiceFileName99999', this.$refs.invoiceImportFile99999.files[0].name)
          let invoiceFile99999;
          invoiceFile99999 = this.$refs.invoiceImportFile99999.files[0];
          form.append('invoiceFile99999', invoiceFile99999);
        }
        if(this.$refs.invoiceImportFile88888.files[0] != undefined)
        {
          form.append('invoiceFileName88888', this.$refs.invoiceImportFile88888.files[0].name)
          let invoiceFile88888;
          invoiceFile88888 = this.$refs.invoiceImportFile88888.files[0];
          form.append('invoiceFile88888', invoiceFile88888);
        }
        if(this.$refs.invoiceTaxImportFile.files[0] != undefined)
        {
          form.append('invoiceTaxFileName', this.$refs.invoiceTaxImportFile.files[0].name)
          invoiceTaxFile = this.$refs.invoiceTaxImportFile.files[0];
          form.append('invoiceTaxFile', invoiceTaxFile);
        }

        if(this.$refs.receiptFile && this.$refs.receiptFile.files[0] != undefined)
        {
          form.append('receiptFileName', this.$refs.receiptFile.files[0].name)
          receiptFile = this.$refs.receiptFile.files[0];
          form.append('receiptFile', receiptFile);
        }

        if(this.$refs.payerNameCsvFile && this.$refs.payerNameCsvFile.files[0] != undefined)
        {
          form.append('payerNameCsvFileName', this.$refs.payerNameCsvFile.files[0].name)
          payerNameCsvFile = this.$refs.payerNameCsvFile.files[0];
          form.append('payerNameCsvFile', payerNameCsvFile);
        }

        form.append('admin_import_id', admin_import_id);
      } else {
        file = this.$refs.deleteInvoiceImportFile.files[0];
      }
      if(enpointUploadData != ENDPOINT.CSV_IMPORT_INVOICE) {
        form.append('file', file);
      }

      const res = await this.$request.post(enpointUploadData, form);
      this.urlFileCustomerError = undefined;
      if (!res.hasErrors() && enpointUploadData !== ENDPOINT.CSV_IMPORT_DELETE_INVOICE) {
        this.__noticeSuccess(this.$t("csv_upload.upload_success"));
        this.urlFileCustomerError = undefined
        this.urlFilePostalCodeError = undefined
      } else if(!res.hasErrors() && enpointUploadData === ENDPOINT.CSV_IMPORT_DELETE_INVOICE) {
        this.__noticeSuccess(this.$t("csv_upload.delete_invoice_success"));
        this.urlFileInvoiceError = undefined
      } else if(!res.hasErrors() && enpointUploadData === ENDPOINT.CSV_IMPORT_INVOICE) {
        this.__noticeSuccess(this.$t("csv_upload.invoice_success"));
      } else {
        if (enpointUploadData == ENDPOINT.CSV_IMPORT_CUSTOMER) {
          this.urlFileCustomerError = res.data.result;
        } else if (enpointUploadData == ENDPOINT.CSV_IMPORT_POSTAL_CODE) {
          this.urlFilePostalCodeError = res.data.result;
        } else if (enpointUploadData == ENDPOINT.CSV_IMPORT_DELETE_INVOICE) {
          this.urlFileInvoiceError = res.data.result;
        } else if (enpointUploadData == ENDPOINT.CSV_IMPORT_INVOICE) {
        }
        if (res.data.errors && res.data.errors.file[0] == '正しい形式のCSVファイルをアップロードしてください。') {
          this.__noticeError(this.$t('csv_upload.upload_file_incorrect_format'));
        } else if (res.hasErrors() && res.data.error_msg == 'この操作を行う場合は権限をマンションの担当者にお問い合わせください。') {
          this.getListApartments();
          this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER_MEALS).then(res => {
            let apartments = res.data.apartments
            if (apartments.length == 0) {
              this.logoutAction()
            }
          })
          this.__noticeError(this.$t('csv_upload.mansion_apartment_denied'));
        } else {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('csv_upload.upload_error'));
        }
      }
      if (enpointUploadData === ENDPOINT.CSV_IMPORT_CUSTOMER) {
        this.resetCustomerInput();
      } else if(enpointUploadData === ENDPOINT.CSV_IMPORT_POSTAL_CODE) {
        this.resetPostalCodeInput();
      } else if (enpointUploadData === ENDPOINT.CSV_IMPORT_INVOICE) {
        this.resetInvoiceTaxFileInput();
        this.resetInvoiceFileInput();
        this.resetInvoiceFileInput88888();
        if (this.target_type == 2) {
          this.resetReceiptFileInput();
        }
        this.resetPaymentNameInput();
      } else {
        this.resetDeleteInvoiceInput();
      }
      this.$refs.modal_show_confirm.hide()
      this.$refs.postal_code_modal_show_confirm.hide()
      this.$refs.delete_invoice_modal_show_confirm.hide()
      this.$refs.invoice_modal_show_confirm.hide()
      this.loading = false;
    },
    changeFile() {
      let filePath = this.$refs.importFile.value;
      let fileName = filePath.split('\\');
      if (filePath !== '' && fileName.length !== 0) {
        this.fileName = fileName[fileName.length-1];
      } else {
        this.fileName = 'Choose file';
      }
      this.customerCheckRequire();
    },
    customerCheckRequire() {
      if (!this.filters.apartment_id_equal.value || !this.$refs.importFile.value) {
        this.disable_upload = true;
      } else {
        this.disable_upload = false;
      }
    },
    postalCodeCheckRequire() {
      if (!this.$refs.postCodeImportFile.value) {
        this.postalCodeDisableUpload = true;
      } else {
        this.postalCodeDisableUpload = false;
      }
    },
    postalCodeChangeFile() {
      let postalCodeFilePath = this.$refs.postCodeImportFile.value;
      let postalCodeFileName = postalCodeFilePath.split('\\');
      if (postalCodeFilePath !== '' && postalCodeFileName.length !== 0) {
        this.postalCodeFileName = postalCodeFileName[postalCodeFileName.length-1];
      } else {
        this.postalCodeFileName = 'Choose file';
      }

      this.postalCodeCheckRequire();
    },
    deleteInvoiceCheckRequire() {
      if (!this.$refs.deleteInvoiceImportFile.value) {
        this.deleteInvoiceDisableUpload = true;
      } else {
        this.deleteInvoiceDisableUpload = false;
      }
    },
    deleteInvoiceChangeFile() {
      let deleteInvoiceFilePath = this.$refs.deleteInvoiceImportFile.value;
      let deleteInvoiceFileName = deleteInvoiceFilePath.split('\\');
      if (deleteInvoiceFilePath !== '' && deleteInvoiceFileName.length !== 0) {
        this.deleteInvoiceFileName = deleteInvoiceFileName[deleteInvoiceFileName.length-1];
      } else {
        this.deleteInvoiceFileName = 'Choose file';
      }

      this.deleteInvoiceCheckRequire();
    },
    invoiceChangeFile99999() {
      let invoiceFilePath  = this.$refs.invoiceImportFile99999.value;
      let invoiceFileName = invoiceFilePath .split('\\');
      if (invoiceFilePath  !== '' && invoiceFileName.length !== 0) {
        this.invoiceFileName99999 = invoiceFileName[invoiceFileName.length - 1];
      } else {
        this.invoiceFileName99999 = 'Choose file';
      }
      this.invoiceDisableUpload = this.isDisabledButtonSubmit();
    },
    invoiceChangeFile88888() {
      let invoiceFilePath  = this.$refs.invoiceImportFile88888.value;
      let invoiceFileName = invoiceFilePath .split('\\');
      if (invoiceFilePath  !== '' && invoiceFileName.length !== 0) {
        this.invoiceFileName88888 = invoiceFileName[invoiceFileName.length - 1];
      } else {
        this.invoiceFileName88888 = 'Choose file';
      }
      this.invoiceDisableUpload = this.isDisabledButtonSubmit();
    },
    invoiceTaxChangeFile() {
      let invoiceTaxFilePath  = this.$refs.invoiceTaxImportFile.value;
      let invoiceTaxFileName = invoiceTaxFilePath .split('\\');
      if (invoiceTaxFilePath  !== '' && invoiceTaxFileName.length !== 0) {
        this.invoiceTaxFileName = invoiceTaxFileName[invoiceTaxFileName.length - 1];
      } else {
        this.invoiceTaxFileName = 'Choose file';
      }

      this.invoiceDisableUpload = this.isDisabledButtonSubmit();
    },

    receiptChangeFile() {
      let receiptFilePath  = this.$refs.receiptFile.value;
      let receiptFileName = receiptFilePath .split('\\');
      if (receiptFilePath  !== '' && receiptFileName.length !== 0) {
        this.receiptFileName = receiptFileName[receiptFileName.length - 1];
      } else {
        this.receiptFileName = 'Choose file';
      }

      this.invoiceDisableUpload = this.isDisabledButtonSubmit();
    },

    payerNameCsvFile() {
      let receiptFilePath  = this.$refs.payerNameCsvFile.value;
      let receiptFileName = receiptFilePath .split('\\');
      if (receiptFilePath  !== '' && receiptFileName.length !== 0) {
        this.payerNameCsvFileName = receiptFileName[receiptFileName.length - 1];
      } else {
        this.payerNameCsvFileName = 'Choose file';
      }

      this.invoiceDisableUpload = this.isDisabledButtonSubmit();
    },

    isDisabledButtonSubmit() {
      if (!this.$refs.invoiceImportFile99999.value && !this.$refs.invoiceImportFile88888.value ) {
        return true
      }
      if (this.target_type == 2 && !this.$refs.receiptFile.value ) {
        return true
      }
      return false
    },
    resetCustomerInput() {
      this.filters.apartment_id_equal.value = '';
      this.fileName = 'Choose file';
      this.$refs.importFile.value = null;
      this.disable_upload = true;
    },
    resetPostalCodeInput() {
      this.postalCodeFileName = 'Choose file';
      this.$refs.postCodeImportFile.value = null;
      this.postalCodeDisableUpload = true;
    },
    resetDeleteInvoiceInput() {
      this.deleteInvoiceFileName = 'Choose file';
      this.$refs.deleteInvoiceImportFile.value = null;
      this.deleteInvoiceDisableUpload = true;
    },
    resetInvoiceFileInput() {
      this.invoiceFileName99999 = 'Choose file';
      this.$refs.invoiceImportFile99999.value = null;
      this.detailDisableUpload = true;
    },
    resetInvoiceFileInput88888() {
      this.invoiceFileName88888 = 'Choose file';
      this.$refs.invoiceImportFile88888.value = null;
      this.detailDisableUpload = true;
    },
    resetReceiptFileInput() {
      this.receiptFileName = 'Choose file';
      this.$refs.receiptFile.value = null;
      this.detailDisableUpload = true;
    },
    resetPaymentNameInput() {
      this.payerNameCsvFileName = 'Choose file';
      this.$refs.payerNameCsvFile.value = null;
      // this.detailDisableUpload = true;
    },
    resetInvoiceTaxFileInput() {
      this.invoiceTaxFileName = 'Choose file';
      this.$refs.invoiceTaxImportFile.value = null;
      this.invoiceDisableUpload = true;
    },
    handleOpenConfirmModal() {
      this.errors = [];
      if (!this.filters.apartment_id_equal.value) {
        this.errors.push("apartment required");
      }

      if (!this.$refs.importFile.value) {
        this.errors.push('File required.');
      }

      if (!this.errors.length) {
        this.$refs.modal_show_confirm.show()
        return true;
      }
    },
    handleOpenPostalCodeConfirmModal() {
      this.$refs.postal_code_modal_show_confirm.show();
    },
    handleOpenDeleteInvoiceConfirmModal() {
      this.$refs.delete_invoice_modal_show_confirm.show();
    },
    handleOpenInvoiceConfirmModal() {
      this.errors = [];
      // if (!this.$refs.invoiceImportFile99999.value) {
      //   this.errors.push('File required.');
      // }
      if (!this.errors.length) {
        this.$refs.invoice_modal_show_confirm.show();
        return true;
      };
    },
    searchHandle() {

    },
    getListApartments() {
      this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER_MEALS).then(res => {
        this.meta = {
          apartments: res.data.apartments,
        }
        this.meta.apartments.unshift({
          id: "",
          name: "--選択--"
        })
      })
    },
    async changeMonth(dataSelect) {
      this.selectMonth = {
        month: dataSelect.monthIndex,
        year: dataSelect.year,
      }
      let monthNow = dataSelect.monthIndex;
      let monthSelect;
      if (String(monthNow).length == 1) {
        monthSelect = '0' + String(monthNow);
      } else {
        monthSelect = String(monthNow);
      }
      let currentMonth = dataSelect.year + String(monthSelect)
      this.filters.month.value = currentMonth
    },
  },
  mounted: function () {
    this.customerCheckRequire();
    this.postalCodeCheckRequire();
    this.deleteInvoiceCheckRequire();
    this.invoiceDisableUpload = this.isDisabledButtonSubmit();

    this.getListApartments();
    this.$request.get(this.ENDPOINT.UI_HELPER_LIST_TEMPLATE_CSV).then(res => {
      this.urlTemplateCustomer = res.data.customer,
      this.urlTemplatePostalCode = res.data.postal_code,
      this.urlTemplateInvoice = res.data.invoice,
      this.urlTemplateDetail = res.data.invoice_receipt,
      this.urlTemplateDetailInvoice = res.data.invoice_receipt_tax
    })
  },
}
</script>

<style scoped>
@import '../../../assets/pages/upload.scss';
.header-title {
  padding-bottom: 2%;
  font-size: 23px;
}
.header-title h4 {
  font-size: 23px;
}
.custom_description {
  padding-bottom: 1%;
}
.custom-text {
  font-size: 15px;
}
.csv_upload .custom-file-input ~ .custom-file-label::after {
  content: "参照";
  height: calc(2.25rem + 4px);
  padding: .375rem 1.5rem;
  background-color: #007bff;
  color: white;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}
.csv_upload .custom-file-input {
  cursor: pointer;
}
.csv_upload .custom-file-label {
  height: calc(2.25rem + 4px);
  border-color: #9ba2aa;
  white-space: pre;
  word-wrap: normal;
  text-overflow: ellipsis;
  padding-right: 100px;
  padding-left: .46875rem;
}
.csv_upload .h6 {
  font-size: 13px;
  color: #7a7474;
}
</style>
